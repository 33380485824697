import React from 'react'
import './../../Components/CSS/Footer.css';
const Footer = () => {
    return (

        <div className='Footerbackground'>
            <div className="container">
                <footer>
                    <div className='upfooter'>
                        <div className='location'>
                        </div>
                        <div className='someinfo'>
                            <div className='pointer icn'></div>
                            <h1 className='big'>Albion Radar</h1>
                            <div className='small'>Netherland</div>
                            <div className='small'>Einhoven</div>
                            <div className='small'>5612 AZ</div>
                        </div>
                        <div className='pages'>
                        </div>
                    </div>
                    <div className='footerlist'>
                        <div className='center'>
                            <h4 className='title'>Qradar 2024 © All right reserved</h4>
                            <div className='fuck'>
                                <a href="/" class="links">
                                    TOS
                                </a>
                                <a href="/" class="links">
                                    Privacy Policy
                                </a>
                            </div>
                        </div>
                        <div className='social'>
                            <a href="/" class="icons instagram"> </a>
                            <a href="/" class="icons facebook"> </a>
                            <a href="/" class="icons telegram"> </a>
                            <a href="/" class="icons linkedin"> </a>
                        </div>
                        <div className='letsfckschool'>
                            <h2 className='title2'>

                            </h2>
                            <div class="title2">
                                albionradar@protonmail.com
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    )
}
export default Footer;
